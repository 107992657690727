import React from "react";
import * as Icon from "@ant-design/icons";
import ClassIcon from "./images/class.svg";
import SettingIcon from "./images/settings.svg";
import GroupIcon from "./images/group.svg";
import UserSetting from "./images/user-settings-line.svg";
import GearIcon from "./images/gear.svg";
import GearsIcon from "./images/gears.svg";

export const UserSettingOutlined: React.FC<any> = (props): JSX.Element => {
  return (
    <span className="anticon ant-menu-item-icon" role="img">
      <UserSetting
        viewBox="0 0 22 22"
        width="1em"
        height="1em"
        preserveAspectRatio="xMaxYMax meet"
        focusable="false"
        fill="currentColor"
        ariaHidden="true"
      />
    </span>
  );
};

export const ClassOutlined = () => {
  return (
    <span className="anticon ant-menu-item-icon" role="img">
      <ClassIcon
        viewBox="0 0 22 22"
        width="1em"
        height="1em"
        preserveAspectRatio="xMaxYMax meet"
        focusable="false"
        fill="currentColor"
        ariaHidden="true"
      />
    </span>
  );
};

export const SettingOutlined = () => {
  return (
    <span className="anticon ant-menu-item-icon" role="img">
      <SettingIcon
        viewBox="0 0 22 22"
        width="1em"
        height="1em"
        preserveAspectRatio="xMaxYMax meet"
        focusable="false"
        fill="currentColor"
        ariaHidden="true"
      />
    </span>
  );
};

export const GroupOutlined = () => {
  return (
    <span className="anticon ant-menu-item-icon" role="img">
      <GroupIcon
        viewBox="0 0 22 22"
        width="1em"
        height="1em"
        preserveAspectRatio="xMaxYMax meet"
        focusable="false"
        fill="currentColor"
        ariaHidden="true"
      />
    </span>
  );
};

export const GearOutlined = () => {
  return (
    <span className="anticon" role="img">
      <GearIcon
        viewBox="0 0 22 22"
        width="1em"
        height="1em"
        preserveAspectRatio="xMaxYMax meet"
        focusable="false"
        fill="currentColor"
        ariaHidden="true"
      />
    </span>
  );
};

export const GearsOutlined = () => {
  return (
    <span className="anticon" role="img">
      <GearsIcon
        viewBox="0 0 22 22"
        width="1em"
        height="1em"
        preserveAspectRatio="xMaxYMax meet"
        focusable="false"
        fill="currentColor"
        ariaHidden="true"
      />
    </span>
  );
};

const {
  UserOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
  MoneyCollectFilled,
  PlusOutlined,
  LockOutlined,
} = Icon;

export const Icons: any = {
  ...Icon,
  ClassOutlined,
  SettingOutlined,
  GroupOutlined,
  UserSettingOutlined,
  GearOutlined,
  GearsOutlined,
};

export {
  UserOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
  MoneyCollectFilled,
  PlusOutlined,
};

export const RenderIcon = ({ type }): JSX.Element => {
  const MatchIcon = Icons[type];
  
  if (!MatchIcon) return <></>;

  return <MatchIcon></MatchIcon>;
};

export default Icons;
